import React from "react"
import PropTypes from "prop-types"
import styled, { css, useTheme } from "styled-components"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import Span from "@components/elements/span"
import Flex from "@components/elements/flex"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import Button from "@components/elements/button"

const StyledSpan = styled(Span)`
  display: flex;
  ::after {
    font-weight: 700;
    color: ${({ color }) => color};
    font-size: 16px;
    line-height: 50px;
    margin-left: 3px;
    margin-top: -6px;
    margin-bottom: 6px;
  }
  ${({ isNumber }) =>
    isNumber &&
    css`
      ::after {
        content: "/mo";
      }
    `}
`

const PricingInfo = ({ condition, value }) => {
  const { color } = useTheme()
  return (
    <Div margin="auto -1.5rem -1.5rem">
      <Div
        margin={"0.5rem 0 0"}
        width={condition ? "100%" : "0"}
        padding={condition ? "0.25rem 0.75rem 0.25rem 1.5rem" : "0.25rem 0"}
        fontSize="0.7rem"
        background={color.blue400}
        overflow="hidden"
        transition="width 0.3s ease-in-out, padding 0.3s ease-in-out"
        whiteSpace="nowrap"
      >
        <Span color="white" whiteSpace="nowrap" fontWeight="700">
          {value}
        </Span>
      </Div>
    </Div>
  )
}

PricingInfo.propTypes = {
  condition: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

export const PricingHeading = ({
  heading,
  annualPrice,
  monthlyPrice,
  monthly,
  info,
  description,
  ...rest
}) => {
  const { color } = useTheme()
  const isNumber = typeof monthlyPrice === "number"

  return (
    <Flex
      padding={!info && "1.5rem 0"}
      paddingMd={"1.5rem"}
      margin={info && "3.5rem 0 0"}
      {...rest}
      position="relative"
    >
      {heading && (
        <Paragraph
          lineHeight="1"
          fontSize="1.5rem"
          color={color.blue600}
          fontWeight="700"
          margin="0 0 0.5rem"
        >
          {heading}
        </Paragraph>
      )}
      {!info && (monthlyPrice || annualPrice) && (
        <Flex flexFlow="row" alignItems="flex-start">
          <StyledSpan
            fontSize="2rem"
            fontWeight="700"
            color={color.blue600}
            isNumber={isNumber}
            lineHeight="1.3"
          >
            {isNumber ? (
              <>
                <Span color="inherit" area="dollar">
                  $
                </Span>
                <AnimatePresence initial={false} mode="popLayout">
                  {monthly ? (
                    <motion.div
                      key="monthlyPrice"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {new Intl.NumberFormat("en-US").format(monthlyPrice)}
                    </motion.div>
                  ) : (
                    <motion.div
                      key="annualPrice"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {new Intl.NumberFormat("en-US").format(annualPrice)}
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            ) : (
              monthlyPrice
            )}
          </StyledSpan>
        </Flex>
      )}
      {description && (
        <Flex
          margin={monthlyPrice || annualPrice ? "0" : "0.5rem 0 0"}
          flexFlow="row"
          justifyContent="space-between"
        >
          <Paragraph
            maxWidth="20ch"
            maxWidthMd="none"
            marginMd="0 0 .5rem"
            fontSize="1rem"
            fontWeight={"600"}
            lineHeight={1.4}
          >
            {description}
          </Paragraph>
        </Flex>
      )}
      {isNumber && (
        <Span
          hideOnMd
          color={color.blue400}
          lineHeight={1.4}
          margin="-0.2rem 0 1.5rem"
          fontSize=".8rem"
          fontWeight={700}
          opacity={monthly ? "0" : "1"}
          style={{
            transition: "0.3s opacity",
          }}
        >
          Save ${monthlyPrice * 12 - annualPrice * 12}/yr
        </Span>
      )}
      {info}
      {heading && (
        <Div margin="auto 0 0" marginMd="1rem 0 0">
          <Button.Klips
            margin="auto auto 0 0"
            force
            notice={false}
            arrow={false}
            {...rest}
          >
            Get Started
          </Button.Klips>
        </Div>
      )}
    </Flex>
  )
}

PricingHeading.propTypes = {
  heading: PropTypes.string,
  monthlyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  annualPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  monthly: PropTypes.bool,
  info: PropTypes.node,
  description: PropTypes.string,
  hover: PropTypes.string,
  handleYearlyClick: PropTypes.func,
}

import React from "react"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import List from "@components/elements/list"
import Paragraph from "@components/elements/paragraph"
import { useTheme } from "styled-components"
import { Accordion } from "@components/common/accordion"

export const KlipsFAQ = () => {
  const { color } = useTheme()
  return (
    <Div
      textContainer
      margin="0 auto 8rem"
      marginMd="0 auto 6rem"
      marginSm="0 auto 4rem"
    >
      <Heading color={color.blue600} center as="h3" margin="0 0 2rem">
        Frequently Asked Questions
      </Heading>
      <Grid gap="1rem">
        <Accordion title="What are the differences between the Klips and the PowerMetrics product?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Klipfolio offers two products: Klips and PowerMetrics. With Klips,
            you can develop and distribute pixel-perfect dashboards and reports
            to your team and your clients. With PowerMetrics, you can set up you
            team for self-serve data-analysis and dashboards.&nbsp;
            <Anchor color={color.blue400} link="/comparison">
              Read more about the differences in detail.
            </Anchor>
          </Paragraph>
        </Accordion>
        <Accordion title="Does Klipfolio integrate with 3rd party apps?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Klipfolio supports direct connections to hundreds of cloud
            application like Google Analytics, Facebook, Twitter, Salesforce,
            Marketo, Hubspot, Xero, Google Adwords, SQL Databases, Excel/Google
            Sheets and so many more.&nbsp;
            <Anchor
              color={color.blue400}
              link="https://www.klipfolio.com/integrations"
            >
              Click here
            </Anchor>
            &nbsp;for a complete up to date list.
          </Paragraph>
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Don&apos;t see your service on the list? No Problem! In fact,
            we&apos;re aware of over 400 services that our customers have
            connected to themselves. You can learn more about all the data
            source integrations we support&nbsp;
            <Anchor
              color={color.blue400}
              link="https://support.klipfolio.com/hc/en-us/articles/215546548-Klipfolio-Data-Source-Integrations?query=data%20source"
            >
              here
            </Anchor>
            .
          </Paragraph>
        </Accordion>
        <Accordion title="How does the free trial of Klipfolio’s BI Reporting platform work?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Our free trial provides you with almost unlimited access to
            Klipfolio for 14 days. You get the full experience of the depth and
            breadth of the Klipfolio software so you know exactly what you get
            when you become a happy customer.
          </Paragraph>
        </Accordion>
        <Accordion title="Do I need to enter my credit card info to sign up?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            No. You can sign up and use Klipfolio for 14 days without entering
            your credit card details. At the end of your trial, or when you
            decide to launch your BI reporting platform, you will need to pick a
            plan and then enter your credit card details or pay via Paypal.
          </Paragraph>
        </Accordion>
        <Accordion title="What happens after my free trial?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            During the 14-day trial, you can upgrade to any paid plan, or after
            the 14 days, the trial will simply expire. It will not automatically
            convert to a paid plan.
          </Paragraph>
        </Accordion>
        <Accordion title="Can I upgrade or downgrade anytime?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Absolutely. Klipfolio&apos;s flexible payment plans allow you to
            upgrade, downgrade, or cancel at any time as per your contract. Your
            contract is either monthly or annually. For annual plans, the
            cancellation takes effect at the end of the current billing period.
          </Paragraph>
        </Accordion>
        <Accordion title="Are there any setup or onboarding fees?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            There are no setup or hidden onboarding fees with any of our plans.
            Order your first month or annual plan and get started right away.
          </Paragraph>
        </Accordion>
        <Accordion title="What payment methods does Klipfolio accept?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            You can set up a subscription using Visa, Mastercard, American
            Express, or PayPal. There is no required commitment term beyond one
            month. Applicable sales taxes will be added to your invoice. We can
            also set up invoicing for larger payments; just contact our&nbsp;
            <Anchor color={color.blue400} link="mailto:success@klipfolio.com">
              Success Team
            </Anchor>
            .
          </Paragraph>
        </Accordion>
        <Accordion title="Are your prices in American currency?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Yes, all prices are listed in USD.
          </Paragraph>
        </Accordion>
        <Accordion title="How does Klipfolio define a user?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            A user is a named individual who can log into Klipfolio using a
            unique email address. A user can be granted access to your
            dashboard. You can assign&nbsp;
            <Anchor
              color={color.blue400}
              link="https://support.klipfolio.com/hc/en-us/articles/215548588-User-roles-and-access-permissions-233166-"
            >
              different roles to users
            </Anchor>
            &nbsp;such as View-Only, Editor and Admin. You can also assign users
            to custom roles to ensure they only have access to the specific
            dashboards they require, and the specific features and functionality
            they need.
          </Paragraph>
        </Accordion>
        <Accordion title="What if I need to add more dashboards or users?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            No problem. You can add 1 dashboard for $8/month or add 5 dashboards
            for $25/month. To add users you will simply need to upgrade your
            plan.
          </Paragraph>
        </Accordion>

        <Accordion title="What if I exceed my plan’s limits?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            No problem. Klipfolio will automatically notify you within the app
            when you run into a limit for your plan. Common limits that you get
            notified for are the number of users, number of dashboards, data
            size limits, and certain sharing features that are not included in
            the entry-level Plan.
          </Paragraph>
        </Accordion>

        <Accordion title="Can I share a dashboard with a large number of users?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            You can share your dashboards with others by publishing links that
            provide view-only access. Publishing a link to your dashboard makes
            your dashboard accessible to people outside your account while
            maintaining access to all interactive functionality through the rich
            web client.
          </Paragraph>
          <Paragraph color={color.blue600} fontSize="1.1rem">
            When publishing a link to your dashboard, you choose from the
            following options:
          </Paragraph>
          <List color={color.blue600} fontSize="1.1rem">
            <li>Make it public and available via search</li>
            <li>Make it available to anyone with the link</li>
            <li>Make it available to anyone with the link and a password</li>
          </List>
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Learn more about&nbsp;
            <Anchor
              link="https://support.klipfolio.com/hc/en-us/articles/215548488-How-do-I-share-my-dashboards-using-public-and-private-links-"
              color={color.blue400}
            >
              Published Links
            </Anchor>
            .
          </Paragraph>
        </Accordion>

        <Accordion title="Can you tell me more about your Client Reporting?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Our Client Reporting is ideal for Agencies, Technology Integrators,
            Consultants and other types of organizations looking to help their
            Clients become more data-driven and stay on top of what is happening
            in their business. With our Program, partners have the ability
            to&nbsp;
            <Anchor
              link="https://support.klipfolio.com/hc/en-us/articles/215548748-Managing-clients"
              color={color.blue400}
            >
              create and manage distinct dashboards for your Clients
            </Anchor>
            . You can create and manage these dashboards from within your own
            Account,
            <Anchor
              link="https://support.klipfolio.com/hc/en-us/articles/221856307-Importing-dashboards-Klips-and-data-sources-to-client-accounts"
              color={color.blue400}
            >
              easily transfer content between Accounts
            </Anchor>
            , and work with as few, or as many Clients as you require. Optional
            add-ons to your subscription include:
          </Paragraph>
          <List color={color.blue600} fontSize="1.1rem">
            <li>
              <Anchor
                color={color.blue400}
                link="https://support.klipfolio.com/hc/en-us/articles/216183707-White-Label-Options"
              >
                White Label
              </Anchor>
              &nbsp;to suppress Klipfolio branding.
            </li>
            <li>
              Implement Domain Aliasing to have your users log in through a
              domain that you specify.
            </li>
            <li>
              <Anchor
                color={color.blue400}
                link="https://support.klipfolio.com/hc/en-us/articles/215548688-Creating-a-custom-theme"
              >
                White Label
              </Anchor>
              &nbsp;to overhaul the look and theme of Klipfolio to align with
              your own brand.
            </li>
          </List>
          <Paragraph>
            Want to learn more?&nbsp;
            <Anchor
              color={color.blue400}
              link="https://www.klipfolio.com/partners"
            >
              Visit our partner page
            </Anchor>
            , or reach out to&nbsp;
            <Anchor link="mailto:success@klipfolio.com">
              success@klipfolio.com
            </Anchor>
            &nbsp;for a chat.
          </Paragraph>
        </Accordion>

        <Accordion title="What is the white label add-on?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            As an add-on to your subscription, our white label options give
            Customers and Partners the ability to customize everything from the
            product name and links to logos and favicons. It&apos;s all up to
            you. White Labeling allows you to completely hide our name and brand
            from the application, and replace it with your own. It also allows
            you to have your users log in through a domain that you specify
            (like dashboard.yourcompany.com), and apply custom theming through
            CSS. If you do not need to completely White Label the platform,
            Domain Aliasing and Custom Theming can also be applied as separate
            add-ons to your account.
          </Paragraph>
        </Accordion>

        <Accordion title="What support resources are available to me?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            World-class support is included in all pricing plans! Everyone gets
            access to:
          </Paragraph>
          <List color={color.blue600} fontSize="1.1rem">
            <li>
              Our Support Team - just submit a ticket via the&nbsp;
              <Anchor link="https://support.klipfolio.com/hc/en-us">
                Help Centre
              </Anchor>
              .
            </li>
            <li>
              <Anchor link="https://support.klipfolio.com/hc/en-us#kb">
                Product knowledge base
              </Anchor>
              .
            </li>
            <li>
              <Anchor link="https://support.klipfolio.com/hc/en-us/community/topics">
                Community Forums
              </Anchor>
              .
            </li>
            <li>
              <Anchor link="https://www.youtube.com/channel/UCPxmHZ0vlQJYS5bZOOutTsw">
                YouTube Channel
              </Anchor>
              .
            </li>
          </List>
        </Accordion>

        <Accordion title="Do you offer priority support?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Yes. Priority Support for faster response times via email is
            available for $49/month. For those who need a bit of extra help,
            our&nbsp;
            <Anchor link="https://www.klipfolio.com/klips-client-reporting/power-ups">
              Data Hero Services
            </Anchor>
            &nbsp;provides a bank of 1:1 consulting time every month and
            priority ticket handling. Data Hero Services start at $199/month,
            available monthly with no minimum commitment, or annually with an
            annual discount.
          </Paragraph>
        </Accordion>

        <Accordion title="Are Professional Services available?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Absolutely! There are a number of options available depending on
            your specific requirements. Our Klipfolio experts can take on
            certain projects, whereas in other cases, working with a skilled
            Klipfolio Partner may make more sense.&apos;
            <Anchor
              color={color.blue400}
              link="https://www.klipfolio.com/about/contact-us"
            >
              Let us know a few details on your project
            </Anchor>
            &nbsp;and we&apos;ll do our best to help!
          </Paragraph>
        </Accordion>

        <Accordion title="Who are Klipfolio’s customers?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Klipfolio is trusted by tens of thousands of data-driven leaders in
            over 50 countries from small SMB&apos;s to the largest Fortune 500
            companies. Due to the breadth and depth of our solution we can
            support almost any use case. Learn more&nbsp;
            <Anchor
              color={color.blue400}
              link="https://www.klipfolio.com/clients"
            >
              here
            </Anchor>
            .
          </Paragraph>
        </Accordion>

        <Accordion title="Is my data safe?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            We take data security very seriously at Klipfolio. Amazon is our
            hosting partner, and our servers are hosted in a world class data
            center. Find more detailed information publicly accessible on our
            website&nbsp;
            <Anchor color={color.blue400} link="/legal/security">
              here
            </Anchor>
            .
          </Paragraph>
        </Accordion>

        <Accordion title="How is Klipfolio committed to GDPR compliance?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Please see our&nbsp;
            <Anchor color={color.blue400} link="/legal/dpa">
              Klipfolio Data Processing Addendum
            </Anchor>
            .
          </Paragraph>
        </Accordion>

        <Accordion title="Does Klipfolio have a discount rate for Not-for-Profit organizations?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Absolutely. We&apos;re proud of, and humbled by, the many
            Not-for-Profit organizations around the world who choose
            Klipfolio.&nbsp;
            <Anchor color={color.blue400} link="/about/contact-us">
              Get in touch with our team to learn more
            </Anchor>
            .
          </Paragraph>
        </Accordion>

        <Accordion title="Have more questions?">
          <Paragraph color={color.blue600} fontSize="1.1rem">
            Of course you do! We&apos;re here to help you at every step of your
            journey.&nbsp;
            <Anchor color={color.blue400} link="/about/contact-us">
              Contact us
            </Anchor>
            &nbsp;anytime!
          </Paragraph>
        </Accordion>
      </Grid>
    </Div>
  )
}

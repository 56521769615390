import React, { useCallback, useEffect, useState } from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import { PricingIcons } from "@components/pages/pricing/pricing-icons"
import { AnimatePresence, motion } from "framer-motion"
import Div from "@components/elements/div"
import { useLocation } from "@hooks/use-location"
import { PRODUCTS } from "@hooks/use-product"
import styled from "styled-components"
import { GetADemoForm } from "@components/forms/get-a-demo"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import { useNeutralGradient } from "@hooks/use-neutral-gradient"
import { KlipsDetails } from "@components/pages/pricing/klips/details"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import { KlipsPricing } from "@components/pages/pricing/klips/pricing"
import { FadeHeading } from "@components/elements/motion/heading"
import { MotionDiv } from "@components/elements/motion"
import { FollowGradient } from "@components/common/gradient"

const StyledBackground = styled(Div)`
  background: linear-gradient(#013950, #012330);
  transition: background-position 3s, background-color 1s;
  clip-path: inset(0 0 0 0);
  position: relative;
  padding: 9rem 0 0;
`

const PricingPage = ({ data }) => {
  const location = useLocation()
  const images = processImages(data.images.edges)
  const params = new URLSearchParams(location.search)
  const [type, setType] = useState()

  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const [monthly, setMonthly] = useState(false)

  const handleMonthlyClick = useCallback(() => {
    setMonthly(true)
  }, [])
  const handleYearlyClick = useCallback(() => {
    setMonthly(false)
  }, [])

  const { gradient } = useNeutralGradient()

  useEffect(() => {
    setType(params.get("type"))
  }, [])

  useEffect(() => {
    const newType = params.get("type")

    if (newType !== type) {
      setType(newType)
    }
  }, [params])

  return (
    <Layout
      title={`Business Dashboards and Reporting Software for Everyone`}
      description={`Klipfolio is the leading platform for self-service BI, dashboards, and reporting. From startups to Fortune 500 teams, Klipfolio provides the power and flexibility you always wanted at an affordable price. Succeed with data analytics today and start for FREE.`}
      fullWidth
      marginless
      productOverride={PRODUCTS.KLIPS}
      hideOverflow
      login
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <StyledBackground>
        <AnimatePresence mode="wait">
          <motion.div
            key="initialRender"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <FollowGradient top={"6rem"} src={gradient.cdn} />
          </motion.div>
        </AnimatePresence>

        <Div position="relative" container>
          <header
            style={{
              margin: "0 auto",
              position: "relative",
              zIndex: 1,
            }}
          >
            <motion.div
              key="initialRender"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <FadeHeading
                key="klipsHeading"
                center
                maxWidth="35ch"
                color={"#9EE6FA"}
                fontSize="2.8rem"
                lineHeight="120%"
                margin="0 auto 4rem"
              >
                Custom dashboards,
                <br />
                affordable pricing.
              </FadeHeading>
            </motion.div>
          </header>

          <MotionDiv
            borderRadius="1.5rem"
            background="white"
            backgroundMd="none"
            transition={{
              duration: 0.5,
            }}
            margin="0 0 1rem"
          >
            <motion.div
              key="initialRender"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <KlipsPricing
                images={images}
                type={type}
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            </motion.div>
          </MotionDiv>

          <Paragraph
            color="white"
            fontWeight={700}
            center
            lineHeight={1.4}
            fontSize="1rem"
            margin="1rem auto 5rem"
            maxWidth="1000px"
          >
            Prices shown in USD/mo, based on an annual subscription and billed
            annually. Business plan is only available as an annual
            subscription.&nbsp;
            <Anchor
              color="white"
              link={"/services"}
              style={{ textDecoration: "underline" }}
            >
              Learn more
            </Anchor>
            &nbsp;about Onboarding, Training, Professional Services and Proof of
            Concept options.
          </Paragraph>
        </Div>
      </StyledBackground>

      <KlipsDetails handleClick={handleClick} />
      <KlipsEOPCta
        force
        heading={
          <span style={{ display: "block", maxWidth: "26ch" }}>
            Discover the power of Klips for you and your team
          </span>
        }
        description={
          "Try Klips for free for 14 days to uncover the features and discover what you can build."
        }
        padding="6rem 0"
      />

      <PricingIcons />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PricingPageQuery {
    images: allDirectusFiles(
      filter: { directus_id: { in: ["01c7bbb0-277d-499b-be8a-46c14c00f95f"] } }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`

export default PricingPage

import React from "react"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import { KlipsFeatureCard } from "./feature-card"
import Anchor from "@components/elements/anchor"
import { useTheme } from "styled-components"

export const KlipsServices = () => {
  const { color } = useTheme()
  return (
    <Flex container margin="0 auto 8rem" marginMd="0 auto 6rem" marginSm="0 auto 4rem">
      <Heading color={color.blue600} margin="0 0 1rem" center as="h2">
        Klips Services
      </Heading>
      <Paragraph color={color.blue700} center margin="0 auto 2rem" maxWidth="900px" lineHeight={1.5}>
        Save time, reduce risk, and get great results. Our data specialists and
        engineers will work with you to close gaps quickly and help deliver a
        solution that aligns with your business goals.
      </Paragraph>
      <Grid columns="1fr 1fr" columnsMd="1fr" gap="2rem">
        <KlipsFeatureCard
          title="Proof of Concept"
          description="Accelerate your evaluation process with a 60-day proof of concept project, performed by our Professional Services team."
          appendix="100% of the fee can be applied to a Business license."
          link={
            <Anchor color={color.blue500} link={"/services"} fontSize="1.25rem">
              Learn more
            </Anchor>
          }
        />

        <KlipsFeatureCard
          title="Onboarding and Training"
          description="With customized training plans, our experts will guide your team on how to get the most out of Klipfolio products and deliver the right data to the right people."
          link={
            <Anchor color={color.blue500} link={"/services"} fontSize="1.25rem">
              Learn more
            </Anchor>
          }
        />

        <KlipsFeatureCard
          title="Implementation Services"
          description="We’ll get it done together. Think of our experts as your extended team. From data stack integration to metric and dashboard creation, rely on us to get your system up and running."
          link={
            <Anchor color={color.blue500} link={"/services"} fontSize="1.25rem">
              Learn more
            </Anchor>
          }
        />
      </Grid>
    </Flex>
  )
}

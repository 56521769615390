import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import React, { useCallback } from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import { navigate } from "gatsby"

export const KlipsSelector = ({ agency, setAgency, ...rest }) => {
  const { color } = useTheme()

  const handleAgencyClick = useCallback(() => {
    setAgency(true)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("type", "agency")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  const handleBusinessClick = useCallback(() => {
    setAgency(false)
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("type")
    navigate(`?${urlParams.toString()}`, { replace: true })
  }, [])

  return (
    <Flex
      borderRadius={"1rem 1rem 0 0"}
      background="white"
      overflow="hidden"
      width="100%"
      flexFlow="row"
      alignSelf="center"
      padding="1rem 0"
      paddingMd="1rem"
      {...rest}
    >
      <Div
        cursor="pointer"
        width="50%"
        onClick={handleBusinessClick}
        center
      >
        <Div
          background={!agency ? color.blue100 : null}
          transition="background-color 0.2s ease-in-out"
          width="max-content"
          margin="0 auto"
          padding="0.5rem 2rem"
          paddingMd="0.5rem 1rem"
          borderRadius="1rem"
        >
          <Span color={color.blue600} fontWeight={!agency ? "700" : "600"}>
            Plans For Businesses
          </Span>
        </Div>
      </Div>
      <Div
        cursor="pointer"
        width="50%"
        onClick={handleAgencyClick}
        center
      >
        <Div
          background={agency ? color.blue100 : null}
          transition="background-color 0.2s ease-in-out"
          width="max-content"
          margin="0 auto"
          padding="0.5rem 2rem"
          paddingMd="0.5rem 1rem"
          borderRadius="1rem"
        >
          <Span color={color.blue600} fontWeight={agency ? "700" : "600"}>
            Plans For Agencies
          </Span>
        </Div>
      </Div>
    </Flex>
  )
}

KlipsSelector.propTypes = {
  agency: PropTypes.bool.isRequired,
  setAgency: PropTypes.func.isRequired,
  rest: PropTypes.object,
}

export const Selector = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
  ...rest
}) => {
  const { color, shadow } = useTheme()

  return (
    <Flex
      boxShadow={shadow.soft}
      padding="0.5rem"
      borderRadius={"3rem"}
      background="white"
      width="min-content"
      flexFlow="row"
      alignSelf="center"
      {...rest}
    >
      <Div
        borderRadius="2rem"
        background={monthly ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.1rem 0.75rem"
        onClick={handleMonthlyClick}
      >
        <Span fontSize="0.9rem" color={color.blue600} fontWeight={700}>
          Monthly
        </Span>
      </Div>
      <Div
        borderRadius="2rem"
        background={!monthly ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.1rem 0.75rem"
        onClick={handleYearlyClick}
      >
        <Span fontSize="0.9rem" color={color.blue600} fontWeight={700}>
          Annual
        </Span>
      </Div>
    </Flex>
  )
}

Selector.propTypes = {
  monthly: PropTypes.bool.isRequired,
  handleMonthlyClick: PropTypes.func.isRequired,
  handleYearlyClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
}

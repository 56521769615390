import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import theme from "../../styles/theme"

const ModalWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  animation: ${props => (props.animation)} 0.5s forwards;
  position: fixed;
  padding: 0 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
`;

const fadeInAnimation = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

const ModalInner = styled.div`
  position: relative;
  max-width: ${props => props.width};
  margin: 0 auto;
`

const ModalForm = styled.div`
  display: flex;
  flex-flow: column;
  background: ${props => props.background ?? "white"};
  max-width: ${props => props.width};
  margin: 10rem auto 0;
  padding: ${props => props.padding ?? "0"};
  text-align: ${props => (props.center ? "center" : undefined)};
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  border-radius: 1rem;
  position: relative;
  padding: 1rem;
  form {
    margin-bottom: 0;
  }
`

const StyledX = styled.button`
  justify-self: flex-end;
  margin-right: 15px;
  width: 15px;
  border: none;
  background: none;
  padding: 0;
  display: block;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;

  :before,
  :after,
  div {
    background: ${({theme}) => theme.color.blue600};
    content: "";
    display: block;
    height: 3px;
    border-radius: 3px;
    margin: 6px 0;
    transition: 0.5s;
  }

  :hover {
    :before,
    :after,
    div {
      background: ${theme.color.blue400};
    }
  }

  :before {
    transform: translateY(10px) rotate(135deg);
  }
  :after {
    transform: translateY(-8px) rotate(-135deg);
  }
  div {
    transform: scale(0);
  }
`

const Modal = ({ show, setModalState, closeButton, children, ...rest }) => {
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    if (show) {
      setAnimation(fadeInAnimation)
    } else if (!show && animation) {
      setAnimation(fadeOutAnimation)
    }
  }, [show, animation, fadeInAnimation, fadeOutAnimation, setAnimation]);
 
  return (
    <ModalWrapper animation={animation} onClick={() => setModalState(false)}>
      <ModalInner {...rest}>
      {closeButton && (
          <StyledX onClick={() => setModalState(false)}>
            <div></div>
          </StyledX>
      )}
      <ModalForm {...rest} onClick={e => e.stopPropagation()}>
        {children}
      </ModalForm>
      </ModalInner>
    </ModalWrapper>
  )
}

Modal.defaultProps = {
  closeButton: true,
  width: "600px",
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setModalState: PropTypes.func,
  closeButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  center: PropTypes.bool,
}

export default Modal

import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import React from "react"
import { PricingColumn } from "../pricing-column"
import styled, { useTheme } from "styled-components"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import media from "../../../../styles/media"
import { PricingHeading } from "../pricing-header"
import {
  KLIPS_AGENCY_COMPARISON,
  KLIPS_AGENCY_LITE,
  KLIPS_AGENCY_LITE_HEADER,
  KLIPS_AGENCY_PREMIER,
  KLIPS_AGENCY_PREMIER_HEADER,
  KLIPS_AGENCY_PRO,
  KLIPS_AGENCY_PRO_HEADER,
  KLIPS_AGENCY_STARTER,
  KLIPS_AGENCY_STARTER_HEADER,
} from "./agency-constants"
import { Selector } from "../selector"

const MobileWrapper = styled(Div)`
  ${media.md_up`
    display: none;
  `}
`

const KlipsAgencyDesktop = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  return (
    <Div
      borderRadius="0 0 1.5rem 1.5rem"
      background="white"
      className="pricing-main"
      hideOnMd
    >
      <Grid
        columns="1.2fr repeat(4, 1fr)"
        columnsMd="1fr"
        borderRadius="1.5rem 1.5rem 0 0"
        zIndex={2}
      >
        <PricingHeading
          info={
            <Selector
              klips
              monthly={monthly}
              handleMonthlyClick={handleMonthlyClick}
              handleYearlyClick={handleYearlyClick}
            />
          }
        />
        <PricingHeading {...KLIPS_AGENCY_STARTER_HEADER} monthly={monthly} />
        <PricingHeading {...KLIPS_AGENCY_LITE_HEADER} monthly={monthly} />
        <PricingHeading {...KLIPS_AGENCY_PRO_HEADER} monthly={monthly} />
        <PricingHeading {...KLIPS_AGENCY_PREMIER_HEADER} monthly={monthly} />
      </Grid>
      <Grid
        columns="1.2fr repeat(4, 1fr)"
        columnsMd="1fr"
        borderRadius="0 0 1.5rem 1.5rem"
        overflow="hidden"
      >
        <PricingColumn first info list={KLIPS_AGENCY_COMPARISON} />
        <PricingColumn button list={KLIPS_AGENCY_STARTER} />
        <PricingColumn button list={KLIPS_AGENCY_LITE} />
        <PricingColumn button list={KLIPS_AGENCY_PRO} />
        <PricingColumn last button list={KLIPS_AGENCY_PREMIER} />
      </Grid>
    </Div>
  )
}

KlipsAgencyDesktop.propTypes = {
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}

const KlipsAgencyMobile = ({ monthly }) => {
  const { shadow } = useTheme()
  return (
    <MobileWrapper className="pricing-mobile">
      <Div
        borderRadius="0 0 1.5rem 1.5rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...KLIPS_AGENCY_STARTER_HEADER} monthly={monthly} />
        <PricingColumn
          mobile
          klips
          button
          list={KLIPS_AGENCY_STARTER}
          titles={KLIPS_AGENCY_COMPARISON}
          monthly={monthly}
        />
      </Div>
      <Div
        borderRadius="1.5rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...KLIPS_AGENCY_LITE_HEADER} monthly={monthly} />
        <PricingColumn
          mobile
          klips
          button
          list={KLIPS_AGENCY_LITE}
          titles={KLIPS_AGENCY_COMPARISON}
          monthly={monthly}
        />
      </Div>
      <Div
        borderRadius="1.5rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...KLIPS_AGENCY_PRO_HEADER} monthly={monthly} />
        <PricingColumn
          mobile
          klips
          button
          list={KLIPS_AGENCY_PRO}
          titles={KLIPS_AGENCY_COMPARISON}
          monthly={monthly}
        />
      </Div>
      <Div
        borderRadius="1.5rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...KLIPS_AGENCY_PREMIER_HEADER} monthly={monthly} />
        <PricingColumn
          mobile
          klips
          button
          list={KLIPS_AGENCY_PREMIER}
          titles={KLIPS_AGENCY_COMPARISON}
          monthly={monthly}
        />
      </Div>
    </MobileWrapper>
  )
}

KlipsAgencyMobile.propTypes = {
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}

export const KlipsAgencyPricing = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <KlipsAgencyDesktop
        monthly={monthly}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
      />
      <KlipsAgencyMobile monthly={monthly} />
    </motion.div>
  )
}

KlipsAgencyPricing.propTypes = {
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}
